import * as React from "react";
import { Card } from "react-bootstrap";
import Newsone from "../../images/Newsone.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon
} from "react-share";
import { Component } from "react"
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, DOMAIN_API, FR, translate, TYPE_FOLDER } from "../../utils"

 

class CardTopMedia extends Component {
  render() {
    const {id,type,typeFR,color,title,titleFR,subTitle,subTitleFR,description,descriptionFR,shortDescription,shortDescriptionFR,fieldsOfActivity,fieldsOfActivityFR,tags,tagsFR,s2500x500,s866x350Banner,s420x350Banner,pdfDownLink,pdfDownLinkFR,s420x350BannerFR} = this.props.item;
    let colorClass
    let nameMenu=translate("Catnews");
  
    if(type === 'news') {
      colorClass = 'green-tag-cover';
      nameMenu=translate("Catnews");
    } else if(type === 'advice') {
      colorClass = "orange-tag-cover";
      nameMenu=translate("Catadvice");
    } else if(type === 'folder') {
      colorClass = 'blue-tag-cover';
      nameMenu=translate("Catfolder");
    } else if(type === 'video') {
      colorClass = 'mauve-tag-cover'; 
      nameMenu=translate("Catvideo"); 
    }else if(type === 'advantages') {
      colorClass = 'red-tag-cover'; 
      nameMenu=translate("advantages"); 
    }
  
     const langs=this.props.langs;
 
    return (
      <div className={`news-cards ${colorClass}`}>
        <Card>
          <span className="title-tag">{nameMenu}</span>
          <div className="news-cover-img">
          {(type === 'folder' && langs===FR)?
          <Card.Img className="" variant="top" src={(s420x350BannerFR)?DOMAIN_API()+s420x350BannerFR:Newsone} />
          :
          <Card.Img className="" variant="top" src={(s420x350Banner)?DOMAIN_API()+s420x350Banner:Newsone} />
          }
            <div className="content">
              <h1 className="h1-tittle">
             { langs=='NL' ? (title)?title:"" : (titleFR)?titleFR:""  }       
              </h1>
            </div>
          </div>
         <div className="group-content">
          <Card.Header>
            <Card.Title>{ langs=='NL' ? (subTitle)?subTitle:"" : (subTitleFR)?subTitleFR:"" }</Card.Title>
            <ul className="list-none m-0">
              <li>
                <FacebookShareButton
                  url={'www.enter-business.com'}
                  quote={'message particulier'}
                  hashtag={'#ENTERBUSINESS'}>
                  <FacebookIcon size={25} round={false} />
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton
                  url={'www.enter-business.com'}
                >
                  <LinkedinIcon size={25} round={false} />
                </LinkedinShareButton>
              </li>
            </ul>
          </Card.Header>
          <Card.Body>
            <Card.Text> 
            { langs=='NL' ? (shortDescription)?shortDescription:"" : (shortDescriptionFR)?shortDescriptionFR:"" }    
            </Card.Text>
            {(type === 'folder')?
              <a style={{cursor: 'pointer'}} target={'__blank'} href={(langs===FR)?(pdfDownLinkFR && (pdfDownLinkFR !== ''))?DOMAIN_API()+pdfDownLinkFR:'#': (pdfDownLink && (pdfDownLink !== ''))?DOMAIN_API()+pdfDownLink:'#'} className="align-self-end read-more stretched-link" >
              {translate('see folder')}
              </a>: (type !== 'video') &&
              <a style={{cursor: 'pointer'}} onClick={this.props.toggleAdviceModal} className="align-self-end read-more stretched-link" >
                <span>+</span> {translate('more')}
              </a>
            }
          </Card.Body>
          </div>
           </Card>
      </div>
    )
  }
}

export default CardTopMedia;
