import * as React from "react"
import axios from "axios"
import { Card, Col, ListGroup, ListGroupItem } from "react-bootstrap"
import settingIcon from "../../images/settings.svg"
import avatar from "../../images/avatar.png"
import backgroundplus from "../../images/deal-swipe-1.png"
import { Component } from "react"
import OwlCarousel from "react-owl-carousel3"
import CardBottomMediaJoachimLeft from "./bottomMediaJoachimLeft"
import {
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  getUserLanguage,
  ROLE_PARTNERCOLLAB,
  ROLE_PARTNERSUPER,
  ROLE_USER,
  translate,
  URL_GETALL_ADVANTAGE_PARTNERS,
} from "../../utils"
import ModalPartnerUnselected from "../modalPartnerUnselected"
var langs = getUserLanguage()
class UserProfile2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModalPartnerUnselected: false,
      idPartnerForModalPartnerUnselected: null,
      activeView: "gridview",
      advantage: [],
    }
  }

  componentDidMount = async () => {
    this.getAll()
  }

  getAll = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL_ADVANTAGE_PARTNERS)
      .then(res => {
        if (res.data) {
          this.setState({ advantage: res.data })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      })
  }

  showModalUnselected = id => {
    this.setState({
      showModalPartnerUnselected: true,
      idPartnerForModalPartnerUnselected: id,
    })
  }
  render() {
    const {
      avatarImg,
      firstName,
      lastName,
      companyName,
      role,
      advantage,
    } = this.props

    return (
      <div className="list-deals-left">
        <Card className="card-ch-t">
          <Card.Body className="card-body-ch">
            {this.state.advantage.map(item => {
              return (
                <div className="position-relative w95">
                  <div
                    className="row zk"
                    onClick={() => {
                      this.props.toggleAdvice(item)
                    }}
                  >
                    <div className="col-md-4 text-center pl-0 pr-0 zk1">
                      <img src={DOMAIN_API() + item.avatarImg} />
                    </div>
                    <div className="col-md-8 f12 zk2">
                      <div className=" pt-2 pb-2 pr-1 ">
                        {langs == "FR"
                          ? item.contentFR
                            ? item.contentFR.slice(0, 58) + "..."
                            : ""
                          : item.contentNL
                          ? item.contentNL.slice(0, 58) + "..."
                          : ""}{" "}
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-right splus"
                    onClick={() => {
                      this.props.toggleAdvice(item)
                    }}
                  >
                    {translate("Bespaar")}
                  </div>
                  <div
                    className="fleshplus"
                    style={{ backgroundImage: `url(${backgroundplus})` }}
                  >
                    {item.val ? (
                      <span>
                        {item.val} <label>{item.symbole}</label>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
            })}
          </Card.Body>
        </Card>
        {this.state.showModalPartnerUnselected ? (
          <ModalPartnerUnselected
            alertTitle={"title"}
            alertMessage={translate(
              "u heeft de partner niet toegevoegd, dus u kunt deze uitkering niet inzien"
            )}
            closeModal={() =>
              this.setState({ showModalPartnerUnselected: false })
            }
            idAdvantage={this.state.idPartnerForModalPartnerUnselected}
          />
        ) : null}
      </div>
    )
  }
}
export default UserProfile2
