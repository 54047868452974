import * as React from "react"
import { Card, ListGroup, ListGroupItem } from "react-bootstrap"
import settingIcon from "../../images/settings.svg"
import avatar from "../../images/avatar.png"
import checkmark from "../../images/check-mark.png"
import { Component } from "react"
import { DOMAIN_API, ROLE_PARTNERCOLLAB, ROLE_PARTNERSUPER, ROLE_USER, translate } from "../../utils"

class UserProfile extends Component{
  //add setting on img
  render() {
    const {avatarImg,firstName,lastName,companyName,role} = this.props
    console.log('role',role)
    console.log('role',((role===ROLE_PARTNERSUPER) || (role===ROLE_PARTNERCOLLAB)),ROLE_PARTNERSUPER)
    return (
      <div className="user-profile">
        <Card>
          {/* <a href={(role===ROLE_USER)?'/auther-profile':((role===ROLE_PARTNERSUPER) || (role===ROLE_PARTNERCOLLAB))?'/partner-profile':"#"}>
          <span className="setting-btn">

          <img
            width={22}
            height={22}
            className="img-fluid"
            src={settingIcon}
            alt=""
          />
        </span>
          </a> */}
           <div className="user-profile-avatar rounded-circle">
            <Card.Img src={(avatarImg)?DOMAIN_API()+avatarImg:avatar} />
          </div>
          <Card.Body>
            <Card.Title>
              {(firstName)?`${firstName} ${lastName}`:''}{" "}
              <span>
              <strong>{(companyName)?companyName:''}</strong> {/*CEO*/}
            </span>
            </Card.Title>
            <div className="scroll-user text-center">
                <p className="mb-1 mt-3">
                  <a className="menu-editprofil flex-column" href="/auther-profile">
                    <img className="w20" src={settingIcon} />
                    {translate("Mon profil")}</a> 
                </p>
                <p className="mb-0">  
                <img className="w20" src={checkmark} />
                  <a  className="menu-editprofil" href="/auther-profile/?preference=open">{translate('Mes preference')}</a>
                </p>
            {/* <ListGroup className="list-group-flush">
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">
                    {translate("belgische bedrijven die vorige maand zijn opgericht")}
                  </div>
                  <div className="list-digits h3-title">
                    <strong>8.500</strong>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('in mijn region')}</div>
                  <div className="list-digits h3-title">1.200</div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('in mijn activiteitengebied')}</div>
                  <div className="list-digits h3-title">2.500</div>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <Card.Text>{translate('top interesses')}</Card.Text>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">
                    {translate('financiën',false,false)}
                  </div>
                  <div className="list-digits h3-title">
                    <strong>12,1%</strong>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('juridisch')}</div>
                  <div className="list-digits h3-title">10,5%</div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('e-commerce')}</div>
                  <div className="list-digits h3-title">9,4%</div>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <Card.Text>{translate("voor mijn activieteitensgebied")}</Card.Text>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">
                    {translate("financiën")}
                  </div>
                  <div className="list-digits h3-title">
                    <strong>12,1%</strong>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('juridisch')}</div>
                  <div className="list-digits h3-title">10,5%</div>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="list-tittle">{translate('e-commerce')}</div>
                  <div className="list-digits h3-title">9,4%</div>
                </div>
              </ListGroupItem>
            </ListGroup> */}
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
/*
const UserProfileOld = () => {
  return (
    <div className="user-profile">
      <Card>
        <Link to={'/auther-profile'}>
          <span className="setting-btn">

          <img
            width={22}
            height={22}
            className="img-fluid"
            src={settingIcon}
            alt=""
          />
        </span>
        </Link>
        <div className="user-profile-avatar rounded-circle">
          <Card.Img src={Men} />
        </div>
        <Card.Body>
          <Card.Title>
            David Niechcicki{" "}
            <span>
              <strong>Intempore</strong> CEO
            </span>
          </Card.Title>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">
                  Belgische bedrijven die vorige maand zijn opgericht
                </div>
                <div className="list-digits h3-title">
                  <strong>8.500</strong>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">In mijn region</div>
                <div className="list-digits h3-title">1.200</div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">In mijn activiteitengebied</div>
                <div className="list-digits h3-title">2.500</div>
              </div>
            </ListGroupItem>
          </ListGroup>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <Card.Text>Top interesses</Card.Text>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">
                  financiën
                </div>
                <div className="list-digits h3-title">
                  <strong>12,1%</strong>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">Juridisch</div>
                <div className="list-digits h3-title">10,5%</div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">e-commerce</div>
                <div className="list-digits h3-title">9,4%</div>
              </div>
            </ListGroupItem>
          </ListGroup>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <Card.Text>Voor mijn activieteitensgebied</Card.Text>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">
                  financiën
                </div>
                <div className="list-digits h3-title">
                  <strong>12,1%</strong>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">Juridisch</div>
                <div className="list-digits h3-title">10,5%</div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="list-tittle">e-commerce</div>
                <div className="list-digits h3-title">9,4%</div>
              </div>
            </ListGroupItem>
          </ListGroup>


        </Card.Body>
      </Card>
    </div>
  )
}
*/
export default UserProfile
