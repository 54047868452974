import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon
} from "react-share";
import { Component } from "react"
import { COLOR_GREEN, COLOR_RED, DOMAIN_API } from "../../utils"

class LeftMedia extends Component {
  render() {
    const {id,type,color,title,titleFR,subTitle,subTitleFR,description,descriptionFR,shortDescription,fieldsOfActivity,fieldsOfActivityFR,tags,tagsFR,s2500x500,s866x350Banner,s420x350Banner} = this.props.item;
    let colorClass = "blue-tag-cover"
    if (color === COLOR_GREEN) colorClass = 'green-tag-cover';
    if (color === COLOR_RED) colorClass = 'red-tag-cover';

    return (
      <div className={`news-cards ${colorClass}`}>
        <Card>
          <Row className="align-items-center">
            <Col>
              <span className="title-tag">{(type)?type:'Dossier'}</span>
              <div className="news-cover-img">
                <Card.Img className="" variant="top" src={(s420x350Banner)?DOMAIN_API()+s420x350Banner:null} />
                <div className="content">
                  <h1 className="h1-title">
                    {(title)?title:""}
                  </h1>
                </div>
              </div>
            </Col>
            <Col>
              <Card.Header>
                <Card.Title>{(subTitle)?subTitle:''}</Card.Title>
                <ul className="list-none m-0">
                  <li>
                    <FacebookShareButton
                      url={'www.enter-business.com'}
                      quote={'message particulier'}
                      hashtag={'#ENTERBUSINESS'}>
                      <FacebookIcon size={25} round={false} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={'www.enter-business.com'}
                    >
                      <LinkedinIcon size={25} round={false} />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {(shortDescription)?shortDescription:''}
                </Card.Text>
                  <span>+</span> more
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}



export default LeftMedia
