import { Link } from "gatsby"
import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"
import Newsone from "../../images/Newsone.png";
import FbGreay from "../../images/fb-gray.svg"
import Ingray from "../../images/in-gray.svg"
import { Component } from "react"
import { COLOR_GREEN, COLOR_RED, DOMAIN_API, FR, TYPE_ADVANTAGE } from "../../utils"

class BottomMediaJoachim extends Component {

  render() {
     const {langs }= this.props
    const {id,type,color,title,titleFR,subTitle,subTitleFR,description,descriptionFR,shortDescription,fieldsOfActivity,fieldsOfActivityFR,tags,tagsFR,s2500x500,s420x350,s866x350,s866x250,s420x700,s420x700FR} = this.props.item;
 
    return (
      <div className="card-joachim">
        <span>{TYPE_ADVANTAGE}</span>
        {(langs===FR)?
         <img src={(s420x700FR)?DOMAIN_API()+s420x700FR:Newsone}/>
        :
        <img src={(s420x700)?DOMAIN_API()+s420x700:Newsone}/>
        }
      </div>
    )
  }
}

export default BottomMediaJoachim;
