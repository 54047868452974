import * as React from "react"
import { Card, ListGroup, ListGroupItem } from "react-bootstrap"
import settingIcon from "../../images/settings.svg"
import avatar from "../../images/avatar.png"
import { Component } from "react"
import { DOMAIN_API, ROLE_PARTNERCOLLAB, ROLE_PARTNERSUPER, ROLE_USER, translate } from "../../utils"
import { LazyLoadImage } from 'react-lazy-load-image-component';
class UserProfileMobile extends Component{
  //add setting on img
  render() {
    const {avatarImg,firstName,lastName,companyName,role} = this.props
    console.log('role',role)
    console.log('role',((role===ROLE_PARTNERSUPER) || (role===ROLE_PARTNERCOLLAB)),ROLE_PARTNERSUPER)
    return (
      <div className="user-profile profil-showme-mobile">
        <Card>

          <a href={(role===ROLE_USER)?'/auther-profile':((role===ROLE_PARTNERSUPER) || (role===ROLE_PARTNERCOLLAB))?'/partner-profile':"#"}>
          <span className="setting-btn">
          <LazyLoadImage
            width={22}
            height={22}
            className="img-fluid"
            src={settingIcon}
            alt=""
          />
        </span>
          </a>
          <div className="user-profile-avatar rounded-circle">
            <Card.Img src={(avatarImg)?DOMAIN_API()+avatarImg:avatar} />
          </div>

      
        </Card>
      </div>
    )
  }
}
 
export default UserProfileMobile
