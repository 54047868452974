import React, { useEffect, useState } from "react"
import {
  Modal,
  Form,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap"
import { isMobile } from "react-device-detect";
import "../styles/main.scss"
import scroll from "../images/scroll.svg"
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY, firstLetterCapitalize, FR, getUserLanguage, NL, translate,
  URL_CREATE_LISTEOFINTEREST_USER, URL_GETALL_INTEREST,
  URL_GETALL_INTEREST_AVAILABLE,
  URL_GETALL_PARTNER_SUPER, URL_MODIFY_OPTION_USER, URL_MODIFY_USER, URL_USER_CREATE_LIST_OF_INTEREST,URL_SEND_USER_TO_API
} from "../utils"
import axios from "axios"
import ModalAlert from "./modalAlert"

// const PopoverCustom = ()=>(
//   <Popover id="popover-one">
//     <Popover.Content>
//       Oui, je souhaite recevoir des réductions, offres et informations de la
//       part d’enter-business (In Tempore srl) pour moi-même et la société que je
//       représente, et j’accepte également le traitement de mes données dans le
//       respect de notre politique de confidentialité
//     </Popover.Content>
//   </Popover>
// )

const LastStepModal = ({ show }) => { 
  const [step, setStep] = useState(1)
  const [optIn1, setoptIn1] = useState(false)
  const [optIn2, setoptIn2] = useState(false)
  const [optIn3, setoptIn3] = useState(false)  
  const [allInterest,setAllInterest] = useState([])
  const [listOfInterest,setlistOfInterest] = useState([])
  const [listOfInterestSelected,setlistOfInterestSelected] = useState([])
  const [allInterestTaked,setallInterestTaked] = useState(false)
  const [showAlert,setshowAlert] = useState(false)
  const [alertTitle,setalertTitle] = useState('')
  const [alertMessage,setalertMessage] = useState('')
  const [disabled,setDisabled] = useState(false)
  const [showMessage1,setShowMessage1] = useState(false)
  const [showMessage2,setShowMessage2] = useState(false)
  const [showMessage3,setShowMessage3] = useState(false)
  const [lang,setLang] = useState(NL)

  useEffect(async () => {
    await setLang(getUserLanguage())
    await getListOfInterest()
  },[])

  const getListOfInterest = async () => {
    axios.get(URL_GETALL_INTEREST)
      .then(res => {
        if (res.data) {
          const language = getUserLanguage();
          const arrayOfInterestName = [];
          if (Array.isArray(res.data)) {
            if (language === FR) {
              res.data.forEach(item => {
                arrayOfInterestName.push(firstLetterCapitalize(item.nameFR))
              })
            }else {
              res.data.forEach(item => {
                arrayOfInterestName.push(firstLetterCapitalize(item.name))
              })
            }
          }
          setlistOfInterest(arrayOfInterestName)
          setAllInterest(res.data)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err)
        console.log('error during get user')
      })
  }

  const onChangeInterestCheckbox = async (e) => {
    setallInterestTaked(false)
    const InterstName = e.target.name;
    const value = e.target.checked;
    const listOfInterestSelectedTemp = [...listOfInterestSelected];
    if (value) {
      listOfInterestSelectedTemp.push(InterstName)
    }else {
      let index = 100000000000000;
      listOfInterestSelectedTemp.forEach((item,i) => {
        if (item === InterstName) index = i;
      })
      listOfInterestSelectedTemp.splice(index,1)
    }
 
    if(listOfInterestSelectedTemp.length>2) setDisabled(true); else   setDisabled(false);
 
    await setlistOfInterestSelected([...listOfInterestSelectedTemp])
  }
 
  const onChangeAllChecked = async (e) => {
    const value = e.target.checked
    if (value) {
      setallInterestTaked(true)
      setlistOfInterestSelected([...listOfInterest])
    }else {
      setallInterestTaked(false)
      setlistOfInterestSelected([])
    }
  }
  const onButtonClickHandler1 = () => {
    setShowMessage1(!showMessage1 );
  };
  const onButtonClickHandler2 = () => {
    setShowMessage2(!showMessage2 );
  };
  const onButtonClickHandler3 = () => {
    setShowMessage3(!showMessage3 );
  };

  const pressContinueButton = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const listOfInterestId = []
    listOfInterestSelected.forEach(item => {
      if (lang === FR) {
        allInterest.forEach(interest => {
          if (firstLetterCapitalize(interest.nameFR) === item) listOfInterestId.push(interest.id)
        })
      }else {
        allInterest.forEach(interest => {
          if (firstLetterCapitalize(interest.name) === item) listOfInterestId.push(interest.id)
        })
      }
    })

    const data = {
      listOfInterestID: listOfInterestId
    }
    axios.post(URL_USER_CREATE_LIST_OF_INTEREST,data,header)
      .then(res => {
        setStep(3)
        if (res.data) {
          console.log(res.data)
        }
      })
      .catch(err => {
        if (err.response && err.response.data) { 
          setshowAlert(true)
      
          setalertTitle(ALERT_MSG_ERROR)

          if (err.response.data === "Minimum of 3 interest are required") {
           
            setalertMessage(translate("Minimum of 3 interest are required"))
          }else setalertMessage(err.response.data.message)

        }
        console.log('error add interest',err)
      })
  }

  const pressBtnStep1 =async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}`,"Access-Control-Allow-Origin":"*" , 'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS', 'Content-type': 'application/json'}
    };
    
   await axios.patch(URL_MODIFY_USER,{optIn1: true,optIn2: true},header)
      .then(res => {
        if (res.data) {
          if(optIn3)
          {
            OptionEdit(header,res.data.id);
          }
          
         SendAPI(header,res.data);

         // step 2 save interested

         
         const listOfInterestId = []
         listOfInterest.forEach(item => {
           if (lang === FR) {
             allInterest.forEach(interest => {
               if (firstLetterCapitalize(interest.nameFR) === item) listOfInterestId.push(interest.id)
             })
           }else {
             allInterest.forEach(interest => {
               if (firstLetterCapitalize(interest.name) === item) listOfInterestId.push(interest.id)
             })
           }
         })
     

         const dataInterest = {
           listOfInterestID: listOfInterestId
         }
         const token = localStorage.getItem(AUTH_TOKEN_KEY)
         const header = {
           headers: { Authorization: `Bearer ${token}` }
         };
          axios.post(URL_USER_CREATE_LIST_OF_INTEREST,dataInterest,header)
           .then(res => {
             setStep(3) 
           })
           .catch(err => { 
             console.log('error add interest',err)
           })


         //
         //setStep(2)
        }
        
 
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          setshowAlert(true)
          setalertTitle(ALERT_MSG_ERROR)
          setalertMessage(err.response.data.message)
        }
        console.log('error during set optin',err)
      })


  
  }

  const OptionEdit=async (header,Token)=>{
    const fr= new FormData();
    fr.append("token",Token)
   await  axios.post(URL_MODIFY_OPTION_USER,fr,header)
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      if (err.response && err.response.data) {
        console.log(err.response.data);
        setshowAlert(true)
        setalertTitle(ALERT_MSG_ERROR)
        setalertMessage(err.response.data.message)
      }
      console.log('error during set optin',err)
    })
  }

  const SendAPI=async (header,data)=>{
    const fr= new FormData();
    fr.append("email",data.account.email);
    fr.append("prenom",data.prenom_1)
    fr.append("nom",data.nom_1)
    fr.append("tel",data.numero_de_telephone)
    fr.append("sexe",data.sexe_1)
    fr.append("DescJuridique",data.description_forme_juridique)
    fr.append("NomCommerce",data.nom_de_commerce)
    fr.append("LangueOperationnelle",data.langue_operationnelle)

   await  axios.post(URL_SEND_USER_TO_API,fr,header)
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      if (err.response && err.response.data) {
        console.log(err.response.data);
        setshowAlert(true)
        setalertTitle(ALERT_MSG_ERROR)
        setalertMessage(err.response.data.message)
      }
      console.log('error during set optin',err)
    })
  }

  const pressLoginButton = async () => {
   // window.location.reload();
    //localStorage.removeItem(AUTH_TOKEN_KEY);
    if(isMobile)
    {
      window.location.href = '/choose_deals_mobile';
    }else{
      window.location.href = '/choose_deals_desktop';
    }
    
  }
 // console.log("zz",listOfInterest.sort((a, b) => a.timeM > b.timeM ? 1:-1));
 
  // switch modal UI based on logics
  switch (step) {
    case 1:
      return (
        <Modal className="modal-show lastStep" show={show} centered>
          <div className="p-5">
              <h1>{translate('enter-business is gratis dankzij onze partners', false)}.
              </h1>{translate('Het is daarom voor ons essentieel dat')}<br/><br />{" "}
            <p>{/*Modifs by Pierre Yellow */}
              <b>{translate('ik ga ermee akkoord om mijn gegevens mee te delen')} :</b>
            </p>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                onClick={() => setoptIn1(!optIn1)}
                label={translate("Enter-business die contact met mij kan opnemen")+"."}
              />
            </Form.Group> 
            <div className="pl-4 pb-4"> 
                <a href="#" className="text-success " onClick={onButtonClickHandler1}>{translate('meer')} +</a>
                {showMessage1 && <p className="smalltxt">{translate('OPtin1')+"."}</p>}
            </div>
             
            <Form.Group controlId="formBasicCheckboxtwo">
              <Form.Check
                type="checkbox"
                onClick={() => setoptIn2(!optIn2)}
                label={translate("zakelijke partners die contact met mij kunnen")+"."}
              />
            </Form.Group>
            <div className="pl-4 pb-4"> 
                <a href="#"  className="text-success" onClick={onButtonClickHandler2}>{translate('meer')} +</a>
                {showMessage2 && <p className="smalltxt">{translate('OPtin2')+"."}</p>}
            </div>

            <Form.Group controlId="formBasicCheckboxtwo1">
              <Form.Check
                type="checkbox"
                onClick={() => setoptIn3(!optIn3)}
                label={translate("Proximus qui peut me contacter")+"."}
              />
            </Form.Group>
            <div className="pl-4"> 
                <a href="#"  className="text-success" onClick={onButtonClickHandler3}>{translate('meer')} +</a>
                {showMessage3 && <p className="smalltxt">{translate('OPtin3')+"."}</p>}
            </div>
            <div class="d-none">
            {listOfInterest.sort((a, b) => a > b ? 1:-1).map((item,index) => (             
                  <Form.Group key={index} controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" id={`all ${index}`} checked={true} onChange={e => onChangeInterestCheckbox(e)} label={item} name={item} />
                  </Form.Group>
                ))}
            </div>
            <div className="text-center py-5">
              <Button
                variant="success"
                type="button"
                onClick={() => pressBtnStep1()}
                disabled={(!optIn1 || !optIn2 )}
              >
                {translate("continuer")}
              </Button>
            </div>
            
          </div>
          {(showAlert)?
            <ModalAlert
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              closeModal={() => setshowAlert(false)}
            />:null
          }
        </Modal>
      )
    case 20:
      return (
        <Modal show={show} centered className="modal-form-wrap modal-show lastStep" onHide={null}>
          <Modal.Header className="pt-2 pb-3">
            <span className="top-fixed-tag-wrap title-tag">{translate('dernière étape')}</span>
            <Modal.Title>
              {translate('mes centres d\'intérêts')}.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  className="row p-0 m-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <p className="pt-3">
              {translate('om uw nieuwsfeed af te stemmen op uw werkelijke behoeften, is het noodzakelijk dat u uw primaire interesses aan ons kenbaar maakt. Of het nu gaat om belastingen, marketing, etc. onze bestanden en nieuws volgen de ontwikkeling van uw bedrijf.')}.
            </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <Form>
          
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  className="gray-secondary font-weight-bold w-100"
                  type="checkbox"
                  label={translate('alles interesseert me')}
                  onChange={e => onChangeAllChecked(e)}
                />
              </Form.Group> 
              <div className="options-scrollable min-vh-20rem">
                {listOfInterest.sort((a, b) => a > b ? 1:-1).map((item,index) => (
                  (allInterestTaked)?
                  <Form.Group key={index} controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" id={`all ${index}`} checked={true} onChange={e => onChangeInterestCheckbox(e)} label={item} name={item} />
                  </Form.Group>:
                  <Form.Group key={index} controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" id={`empty ${index}`} onChange={e => onChangeInterestCheckbox(e)} label={item} name={item} />
                  </Form.Group>
                ))}
                {/*<Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" id="id1" label="interet1" />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" id="id2" label="interet2" />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" id="id3" label="interet3" />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" id="id4" label="interet4" />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" id="id5" label="interet5" />
                </Form.Group>*/}
              </div>
   
              <Form.Group
                controlId="formBasicCheckbox"
                className="d-flex justify-content-center"
              >
                {/*<Form.Check type="checkbox" />
                <Form.Text className="text-muted">
                  I consent to the{" "}
                  <Link to="#" className="text-link">
                    privacy policy of Enter Business
                  </Link>{" "}
                </Form.Text>*/}
              </Form.Group>
              <Button
                variant="success"
                type="button"
                onClick={() => pressContinueButton()}
                disabled={(listOfInterestSelected.length < 3)}
              >
              
                {translate("continuer")}
              </Button>
            </Form>
          
          
          
            </div>
          
          </Modal.Body>
          {/*<Modal.Footer>
            <div className="modal-nav">
              <ul>
                <li>
                  <Link to="#">Site Terms</Link>
                </li>
                <li>
                  <Link to="#">Privacy</Link>
                </li>
                <li>
                  <Link to="#">Cookies Policy</Link>
                </li>
              </ul>
            </div>
          </Modal.Footer>*/}
          {(showAlert)?
            <ModalAlert
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              closeModal={() => setshowAlert(false)}
            />:null
          }
        </Modal>
      )
    case 3:
      return (
        <Modal className="modal-show lastStep" show={show} centered>
          <div className="text-center" style={{ padding: "10rem 4rem" }}>
            <h1 style={{marginBottom: '25px'}}>{translate('bravo et bienvenue')}!</h1>
            <p>
              {translate('Un e-mail avec votre mot de passe provisoire vient de vous être envoyé, merci de le confirmer pour commencer à utiliser la plateforme')+"."}
            </p>
            <Button variant="success" type="button" onClick={() => pressLoginButton()}>
              {translate('Close')}
            </Button>
          </div>
          {(showAlert)?
            <ModalAlert
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              closeModal={() => setshowAlert(false)}
            />:null
          }
        </Modal>
      )

    default:
      break
  }
  // return <>{step === 1 ? <Step1 show={show} /> : null}</>
}

export default LastStepModal
