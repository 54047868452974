import React, { Component } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import axios from "axios"

import Layout from "../components/layout"
import Header from "../components/header"
import UserProfile from "../components/cards/userProfile"
import UserProfile2 from "../components/cards/userProfile2"
import OurPartners from "../components/cards/ourPartners"
import CardTopMedia from "../components/cards/topMedia"
import UserProfileMobile from "../components/cards/UserProfileMobile"
import CardBottomMedia from "../components/cards/bottomMedia"
import CardBottomMediaJoachim from "../components/cards/bottomMediaJoachim"
import CardLeftMedia from "../components/cards/leftMedia"
import CardFullTopMedia from "../components/cards/topFullMedia"
import CardQuote from "../components/cards/cardQuote"
import SinglePost from "../components/cards/singlePost"
import MessagesCard from "../components/cards/messagesCard"
import LastStepModal from "../components/lastStep"
import AdvantageModal from "../components/modalAdvantage"
import AdviceModal from "../components/modalAdvice"
import MessagesNotification from "../images/messages-notification.svg"
import Footer from "../components/footerfeed"
import Sticky from 'react-sticky-el';
import ModalVideo from 'react-modal-video'
import { isMobile } from "react-device-detect";
import $ from "jquery"

import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES,
  URL_GETALL_FOLDERS,
  URL_GETALL_NEWS,
  URL_GETALL_VIDEOS,
  URL_GETALL,
  URL_IS_AUTH,
  translate,
  URL_GET_MAINPARTNERSUPER_DATA,
  URL_GETALL_PARTNER_SUPER_FILTERED,
  getUserLanguage,KEY_LANGUAGE_USER,KEY_LANGUAGE_BROWSER,checkurlvideo,
  NL,
  URL_GETALL_FILTERER_BY_KEYPHRASE, URL_GET_ACCOUNT_MESSAGES, FR
} from "../utils"
import ModalAlert from "../components/modalAlert"
import ModalPartnerUnselected from "../components/modalPartnerUnselected"



var langs = getUserLanguage();


class Feed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountId: "",
      email: "",
      role: "",
      allFeed: [],
      allAdvantages: [],
      allAdvices: [],
      allFolders: [],
      allNews: [],
      allVideos: [],
      showAll: true,
      showFolder: false,
      showArticle: false,
      showNew: false,
      showVideo: false,
      showAdvantage: false,
      user: null,
      showLastStepModal: false,
      activeAdvantage: undefined,
      activeAdvice: undefined,
      activeFolder: undefined,
      activeNews: undefined,
      activeVideo: undefined,
      showAdvantageModal: false,
      showAdviceModal: false,
      showFolderModal: false,
      showNewsModal: false,
      showVideoModal: false,
      showAdantages: false,
      optIn1: true,
      optIn2: true,
      partner: null,
      allPartnerSuper: [],
      isActive: false,
      userMessages: [],
      showModalPartnerUnselected: false,
      idPartnerForModalPartnerUnselected: null,
      activeView:'gridview',
      openModel:0,
    }

    ///lang
        if (typeof window !== 'undefined') {
        $( document ).ready(function() {
        let wind= window.location.search;

        let lang  = new URLSearchParams(wind).get("lang");
        if(lang){
          localStorage.setItem(KEY_LANGUAGE_USER,lang);
          localStorage.setItem(KEY_LANGUAGE_BROWSER,lang );
          langs=lang;
        }
        });
        }
//fin  lang
  }



  

  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };


   componentDidMount = async () => {
    await this.auth()
    await this.getOptinUser()
     this.getAll()
    await this.getUser()
    await this.getAllPartnerSuper()
    await this.getUserMessages()

    
    window.addEventListener('scroll', () => {
      let activeClass = ""; 
      let hideHeader=false;
      let hidetopfw=false;
      if(window.scrollY < 5){ 
         hideHeader=false; 
        } else {
        hideHeader=true;
       }
      if(!isMobile){         
        if(window.scrollY < 120){
          activeClass = ''; 
        } else {
          activeClass = 'posfix';
          hidetopfw='true';
        }
      }
      this.setState({ hideHeader: hideHeader});
      this.setState({ hidetopfw: hidetopfw});
      this.setState({ activeClass});
  })
  
  


}

  suffleFeed = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  getOptinUser = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_GET_USER_FULL, data)
      .then(res => {
        if (res.data) {
          const userData = res.data;
          const {optIn1,optIn2} = userData;
          if (!optIn1 || !optIn2) this.setState({showLastStepModal: true})
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err);
        }
        console.log('error during get user')
      })
  }


  getAllFilteredByKeyPhrase = (searchPhrase) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const langs=localStorage.getItem(KEY_LANGUAGE_USER)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }
    
    const data = {
      searchPhrase,
      language: (langs)?langs:NL
    }
    axios
      .post(URL_GETALL_FILTERER_BY_KEYPHRASE,data,header)
      .then(res => {
        if (res.data) {
          this.setState({ allFeed: this.suffleFeed(res.data) })
        }
        this.forceUpdate();
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err);
        }
      })
  }

  auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_IS_AUTH, data)
      .then(res => {
        if (res.data) {
          const { id, email, role } = res.data
          this.setState({ accountId: id })
          this.setState({ email: email })
          this.setState({ role: role })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err) 
        sessionStorage.setItem("lastURL",window.location.href)
        window.location.href = "/login"
      })
  }

  getUser = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const url = URL_GET_USER_FULL
    axios
      .get(url, data)
      .then(res => {
        if (res.data) {
          this.setState({ user: res.data })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
        axios.get(URL_GET_MAINPARTNERSUPER_DATA,data)
          .then(res => {
            if (res.data) {
              this.setState({partner: res.data})
            }
          })
          .catch(err => {
            if (err.response) {
              console.log(err.response.data)
            }
            console.log(err)
          })

      })
  }

  getAll =async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL, data)
      .then(res => {
        if (res.data) {
          this.setState({ allFeed: this.suffleFeed(res.data) })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err);
        }
      })
  }



  getAllPartnerSuper = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_GETALL_PARTNER_SUPER_FILTERED, data)
      .then(res => {
        if (res.data) {
          this.setState({ allPartnerSuper: res.data })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err);
        }
      })

  }

  getUserMessages = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_GET_ACCOUNT_MESSAGES, data)
      .then(res => {
        if (res.data) {
          this.setState({ userMessages: res.data })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err);
        }
      })
  }


// mohammed
ModalOpenLink=() => {
  if(this.state.openModel===0){
  $( document ).ready(function() {
    let wind= window.location.search;
    const id = new URLSearchParams(wind).get("id");
    const type = new URLSearchParams(wind).get("type");
     
    if(id && type){
     $(`#openModalInLink-${type}-${id}`).click();
     }
   });
   this.setState({ openModel:1})
  }
}

getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
}

getQueryStringVal = (key)  => {
 if(this.getQuery().get(key))  return this.getQuery().get(key).toUpperCase();
}




  showModalUnselected = (id) => {
    this.setState({
      showModalPartnerUnselected: true,
      idPartnerForModalPartnerUnselected: id,
    })
  }
 
  toggleAdvantageModal = (item) => {
    if(!this.state.showAdvantageModal) {
    let url="?lang="+langs+"&id="+item.id+"&type="+item.type
    window.history.pushState("","",url)


      this.setState({ activeAdvantage: item })
    } else {
      this.setState({ activeAdvantage: undefined })
      window.history.replaceState({}, document.title, "/" + "feed");
    }
    this.setState({ showAdvantageModal: !this.state.showAdvantageModal })
  }

  toggleAdviceModal = (item) => {
    if(!this.state.showAdviceModal) {
      let url="?lang="+langs+"&id="+item.id+"&type="+item.type
      window.history.pushState("","",url)


      this.setState({ activeAdvice: item })
    } else {
      this.setState({ activeAdvice: undefined })
      window.history.replaceState({}, document.title, "/" + "feed");
    }
    this.setState({ showAdviceModal: !this.state.showAdviceModal })
  }

  toogleVideoModal = (item) => {
   
      if(item && (item.id==9 || item.id==10 || item.id==11 || item.id==12) ){
        var urlvideo=""
        if (item.id==9){ 
          if(langs=="FR"){
            urlvideo="https://www.proximus.be/fr/id_b_cb_marketing_material/independants-et-petites-entreprises/blog/news/evenements-activites/outils-pour-la-creation-de-materiel-marketing.html"
          } else {
            urlvideo="https://www.proximus.be/nl/id_b_cb_marketing_material/zelfstandigen-en-kleine-bedrijven/blog/news/events-activiteiten/tools-om-marketingmateriaal-te-maken.html"
          }
        }  
        
        if (item.id==10){ 
          if(langs=="FR"){
            urlvideo="https://www.proximus.be/fr/id_b_cb_work_efficiently_tools/independants-et-petites-entreprises/blog/news/evenements-activites/des-outils-pour-travailler-plus-efficacement.html"
          } else {
            urlvideo="https://www.proximus.be/nl/id_b_cb_work_efficiently_tools/zelfstandigen-en-kleine-bedrijven/blog/news/events-activiteiten/tools-efficienter-te-werken.html"
          }
        } 
  
        if (item.id==11){ 
          if(langs=="FR"){
            urlvideo="https://www.proximus.be/fr/id_b_cb_online_safety/independants-et-petites-entreprises/blog/news/evenements-activites/des-outils-pour-la-securite-en-ligne.html"
            } else {
            urlvideo="https://www.proximus.be/nl/id_b_cb_online_safety/zelfstandigen-en-kleine-bedrijven/blog/news/events-activiteiten/tools-voor-online-veiligheid.html"
          }
        }
  
        if (item.id==12){ 
          if(langs=="FR"){
            urlvideo="https://www.proximus.be/fr/id_b_cb_communicate_with_customers_tools/independants-et-petites-entreprises/blog/news/evenements-activites/des-outils-pour-communiquer-avec-vos-clients.html"
          } else {
            urlvideo="https://www.proximus.be/fr/id_b_cb_communicate_with_customers_tools/independants-et-petites-entreprises/blog/news/evenements-activites/des-outils-pour-communiquer-avec-vos-clients.html"
          }
        }
         
    if (typeof window !== 'undefined' && (urlvideo)) window.location.replace(urlvideo);
    } else {
    if(!this.state.showVideoModal) {
        let linkVideo=(langs===FR)?item.videoDownLinkFR:item.videoDownLink
        let url="?lang="+langs+"&id="+item.id+"&type="+item.type
        window.history.pushState("","",url)

        const indexOfFirst = linkVideo.indexOf("yout");
        if(indexOfFirst>0){
        var youTubeIdFromLink = linkVideo.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/)[1];
        item= "https://www.youtube.com/embed/"+youTubeIdFromLink;
        }

        this.setState({ activeVideo: item })

    } else {
      this.setState({ activeVideo: undefined })
      window.history.replaceState({}, document.title, "/" + "feed");
    }
       this.setState({ showVideoModal: !this.state.showVideoModal })

  }
  }

  filterFunct = (name) => {
    switch(name) {
      case 'all':
        this.setState({
          showAll: true,
          showFolder: false,
          showArticle: false,
          showNew: false,
          showVideo: false,
          showAdvantage: false,
        })
        break;
      case 'folder':
        this.setState({
          showAll: false,
          showFolder: !this.state.showFolder,
        })
        break;
      case 'article':
        this.setState({
          showAll: false,
          showArticle: !this.state.showArticle,
        })
        break;
      case 'news':
        this.setState({
          showAll: false,
          showNew: !this.state.showNew,
        })
        break;
      case 'video':
        this.setState({
          showAll: false,
          showVideo: !this.state.showVideo,
        })
        break;
      case 'advantage':
        this.setState({
          showAll: false,
          showAdvantage: !this.state.showAdvantage,
        })
        break;
    }
    this.forceUpdate();
  }

  componentDidUpdate(state) {
    if(!this.state.showAll && !this.state.showFolder && !this.state.showArticle && !this.state.showNew && !this.state.showVideo && !this.state.showAdvantage) {
      this.setState({
        showAll: true,
      })
    }
  }


  changeView(view){  
    this.setState({activeView: view});
  }


  render() {
    const {
      allFeed,
      showNew,
      showAdvantage,
      showArticle,
      showFolder,
      showVideo,
      showAll,
      user,
      showLastStepModal,
      showAdvantageModal,
      showVideoModal,
      showAdviceModal,partner,
      role,allPartnerSuper,
      isActive,userMessages,hideHeader,showModalPartnerUnselected,idPartnerForModalPartnerUnselected,hidetopfw
    } = this.state



    return (

      <Layout hideHeader={hideHeader}>
        
        {(!hideHeader)?
        <Header searchKey={(key) => this.getAllFilteredByKeyPhrase(key)} />
        :null
        } 
         {(hidetopfw)?
        <div className="topfw"></div>
        :null
         }
         
        <Container className="feed-page pt-3 pb-5 mb-5 position-relative">
          <Row className="justify-content-md-center">
            <Col className="col-md-3 hidme-mobile-tablette-left sidebar">
              <Sticky   topOffset={0}>
              <h1 className="h1-title mb-4">
                  {translate('Mon Profil')}
                  </h1>
              <div className="options-scrollable-h showscrol" ref={this.myRef}  >
    
                <UserProfile
                  avatarImg={user ? user.avatarImg :(partner)?partner.avatarImg:undefined}
                  firstName={user ? user.prenom_1 : (partner && partner.partner && partner.partner.account)?partner.partner.account.email:''}
                  lastName={user ? user.nom_1 : ''}
                  companyName={user ? user.nom_de_commerce : (partner)?partner.name:undefined}
                  role={role}
                />
                <div className="our-partners   clearfix">
                  <h4>{translate('Onze partners')}</h4>
                  {allPartnerSuper.map(item => {
                    return(
                      <OurPartners  item={item} />
                    )  })}
                </div>

                <Footer />
                </div>
              </Sticky>


            </Col>

            <Col className="center col-md-6 showme-mobile-tablette">

              <UserProfileMobile
                avatarImg={user ? user.avatarImg :(partner)?partner.avatarImg:undefined}
                firstName={user ? user.prenom_1 : (partner && partner.partner && partner.partner.account)?partner.partner.account.email:''}
                lastName={user ? user.nom_1 : ''}
                companyName={user ? user.nom_de_commerce : (partner)?partner.name:undefined}
                role={role}
              />
              <div className={this.state.activeClass}>
              <h1 className="h1-title mb-4">
                {translate('Uw feed')}
                <div className="btn-group btn-listview">
                <a href="#" onClick={() => this.changeView('listview') } id="listview" className="btn btn-default btn-sm">
                <svg enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 0h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 0h-14c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m5 9h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 9h-14c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m5 18h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 18h-14c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/></svg> List</a>
                <a href="#" onClick={() => this.changeView('gridview') } id="gridview" className="btn btn-default btn-sm">
                <svg enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 0h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m5 9h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m5 18h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m14 0h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m14 9h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m14 18h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 0h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 9h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/><path d="m23 18h-4c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1z"/></svg> Grid</a>
                </div>
           
              </h1>
              <div className="tab-nav-wrap mb-2 categories menumob clearfix">
                <a href="#"><h1 className="first_h1">filter</h1></a>
                <div className="dropdown">
                  <button  className="dropbtn" onClick={() => this.handleToggle()}><svg height="16pt" viewBox="0 -53 384 384" width="16pt" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg></button>
                  <ul className={(isActive)? "dropdown-content show":"dropdown-content"} >
                    <li
                      onClick={() => this.filterFunct('all')}
                    >
                      <a className={ showAll ? 'btn btn-outline all active' : 'btn btn-outline all' }>{'_'+translate('all')}</a>
                    </li>
                    <li onClick={() => this.filterFunct('folder')}>
                      <a className={ showFolder ? 'btn btn-outlin dossier active' : 'btn btn-outlin dossier' }>{'_'+translate('dossiers')}</a>
                    </li>
                    <li onClick={() => this.filterFunct('article')}>
                      <a className={ showArticle ? 'btn btn-outline article active' : 'btn btn-outline article' } >{'_'+translate('article')}</a>
                    </li>
                    {/* <li onClick={() => this.filterFunct('news')}>
                      <a className={ showNew ? 'btn btn-outline news active' : 'btn btn-outline news' }>{'_'+translate('news')}</a>
                    </li> */}
                    <li onClick={() => this.filterFunct('video')}>
                      <a className={ showVideo ? 'btn btn-outline video active' : 'btn btn-outline video'}>{'_'+translate('videos')}</a>
                    </li>
                    <li onClick={() => this.filterFunct('advantage')}>
                      <a className={ showAdvantage ? 'btn btn-outline advantage active' : 'btn btn-outline advantage' }>{'_'+translate('deal')}</a>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
             
      
              <div className="scroll-feed">
                <Row>
                  {allFeed.map(item => {
                    if(item.type === 'advantage' && (showAll || showAdvantage)) {
                      if(item.isPartnerSelected) {
                        return (
                          <Col id={`openModalInLink-advantage-${item.id}`}  lg="6" onLoad={this.ModalOpenLink} className={this.state.activeView}  onClick={() => this.toggleAdvantageModal(item) }>
                              <CardBottomMediaJoachim item={item} langs={langs} />
                          </Col>
                         )
                      } else {
                        return (
                          <Col  id={`openModalInLink-advantage-${item.id}`}    lg="6" className={`openModalInLink ${this.state.activeView}`}   onClick={() => this.showModalUnselected(item.id) }>
                            <CardBottomMediaJoachim item={item} langs={langs} />
                          </Col>
                        )
                      }
                    } else if(item.type === 'advice' && (showAll || showArticle)) {
                      return (
                        <Col  id={`openModalInLink-advice-${item.id}`}    lg="6"  className={`d-flex align-content-stretch openModalInLink ${this.state.activeView}`}  onClick={() => this.toggleAdviceModal(item) }>
                          <CardTopMedia item={item} langs={langs}    />
                        </Col>
                      )
                    } else if(item.type === 'folder' && (showAll || showFolder)) {
                      return (
                        <Col  id={`openModalInLink-folder-${item.id}`}    lg="6" className={`d-flex align-content-stretch  ${this.state.activeView}`}  /*onClick={() => this.toggleAdviceModal(item) }*/>
                          <CardTopMedia item={item} langs={langs}  toggleAdviceModal={this.toggleAdviceModal} />
                        </Col>
                      )
                    } else if(item.type === 'news' && (showAll || showNew)) {
                      return (
                        <Col  id={`openModalInLink-news-${item.id}`}    lg="6" className={`d-flex align-content-stretch openModalInLink ${this.state.activeView}`}  onClick={() => this.toggleAdviceModal(item) }>
                          <CardTopMedia item={item} langs={langs}   />
                        </Col>
                      )
                    } else if(item.type === 'video' && (showAll || showVideo)) {
                      return (
                        <Col  id={`openModalInLink-video-${item.id}`}    lg="6" className={`d-flex align-content-stretch openModalInLink ${this.state.activeView}`} onClick={() => this.toogleVideoModal(item) }>
                        <CardTopMedia item={item} langs={langs}  />
                        </Col>

                      )
                    }
                  })}
                  <Col>
                    <SinglePost langs={langs}/>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col className="right col-md-3 hidme-mobile-tablette-right ">
            <Sticky   topOffset={0}>
            <h1 className="h1-title mb-4">
                  {translate('Mes offres Deals')}
                </h1>
                <div className="options-scrollable-h showscrol">
    
        

             {allFeed.length>0
              ?
                <UserProfile2
                  avatarImg={user ? user.avatarImg :(partner)?partner.avatarImg:undefined}
                  firstName={user ? user.prenom_1 : (partner && partner.partner && partner.partner.account)?partner.partner.account.email:''}
                  lastName={user ? user.nom_1 : ''}
                  companyName={user ? user.nom_de_commerce : (partner)?partner.name:undefined}
                  role={role}
                  advantage={allFeed.filter(item => item.type=="advantage"  )}
                  toggleAdvice={this.toggleAdvantageModal}
                />
                :""
              }
    
                          <div className="orange-widgets-box rounded-lg mt-4">
                            <h1 className="h1-title font-poppins">
                              {translate('wilt u meer voordelen')+"?"}
                            </h1>
                            <p>{translate("voltooi uw profiel")}</p>
                            <a href={"/auther-profile"}>
                              <Button variant="outline-secondary">
                                {translate('profiel wijzigen')}
                              </Button>
                            </a>
                            <p id=""></p>
                          </div> 
                </div> 
            </Sticky>
           </Col>

          </Row>

        {/* footer show */}






        </Container>
        
        <LastStepModal show={showLastStepModal}/>
        {showVideoModal && <ModalVideo channel='custom' isOpen={showVideoModal} autoplay="1" url={this.state.activeVideo} onClose={this.toogleVideoModal} /> }
        <AdvantageModal show={showAdvantageModal} toggle={this.toggleAdvantageModal} advantage={this.state.activeAdvantage} />
        <AdviceModal show={showAdviceModal} toggle={this.toggleAdviceModal} advice={this.state.activeAdvice} />
       
       
        {(showModalPartnerUnselected)?
          <ModalPartnerUnselected

            alertTitle={'title'}
            alertMessage={translate('u heeft de partner niet toegevoegd, dus u kunt deze uitkering niet inzien')}
            closeModal={() => this.setState({showModalPartnerUnselected: false})}
            idAdvantage={idPartnerForModalPartnerUnselected}
          />:null
        }
      </Layout>
    )
  }
}

export default Feed


 