import * as React from "react";
import { translate } from "../../utils"
const SinglePost = () => {
  return (
    <div className="single-post yellow-gradient mb-5">
      <h3>
        {translate('al onze partners hebben geweldige aanbiedingen voor onze leden')}.
      </h3>
      <p>{translate('voltooi uw profiel om er gebruik van te maken')}.</p>
      <a style={{textTransform: 'uppercase'}} href={"/auther-profile"} className="btn btn-dark">
        {translate('mijn profiel')}
      </a>
    </div>
  );
};

export default SinglePost;
